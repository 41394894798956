import React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import SectionLayout from "../components/sectionLayout"
import Head from "../components/head"
import SubHeader from "../components/subHeader"
import image1 from "../assets/images/1.svg"
import image2 from "../assets/images/2.svg"
import image3 from "../assets/images/3.svg"
import image4 from "../assets/images/4.png"
import image5 from "../assets/images/5.png"
import Button from "../components/button"
import HubspotContactFormPartner from "../components/hubspotContactFormPartner"
import Layout from "../components/layout"


export default function Headless() {
  return (
    <Layout navbar="darkblue">
      <Head
        title="Modern Solutions Designed For Scale & Performance"
        description="Your marketplace operations have never been as seamless with automated importing of your vendor products, syncing information and inventory levels, automated order routing, payments, and more."
      />
      <SectionLayout>
        <BackgroundShape
          style={{ shape: "", color: "dark-blue", particles: false }}
        />
        <Container style={{ classes: "banner-container container" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="header container">
              <h1 className="header__banner-title">
                Onport & Composable <br />
                Commerce
              </h1>
              <p className="header__banner-subtitle">
              In Onport, you’ll find a solution that not only understands the challenges of the modern commerce environment but seeks to empower its users to tackle them head-on. Our enterprise system has been methodically designed with the core principles of being a {" "}
                <b>Microservice-orientated, API-focused, Headless,</b> and{" "}
                <b>Cloud-native solution</b>, at the forefront of each stage of
                development. This approach is known as <b>MACH</b>.
              </p>
            </div>
            <div
              className="position-relative"
              style={{ width: "700px", height: "600px" }}
            >
              <img
                src={image3}
                style={{
                  width: "400px",
                  position: "absolute",
                  top: "25%",
                  left: "0",
                }}
                className="desktop-only"
                alt="home"
                data-sal="slide-left"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="600"
              />
            </div>
          </div>
        </Container>
      </SectionLayout>
      <Container style={{ classes: "container-fluid dark-blue mx-0 px-0" }}>
        <Container
          style={{ classes: "container-fluid pb-5 very-dark-blue mx-0 px-0" }}
        >
          <Container style={{ classes: "container pb-5 mb-5" }}>
            <SubHeader
              content={{
                title: "Composable Commerce powered by MACH",
                text:
                  "By focusing on composable commerce, users are provided with pre-existing infrastructure components to further facilitate faster development. Which components they choose to integrate into their stack is entirely dependent on their particular goals and requirements. Such a modular approach emphasizes flexibility in the development process and replaces rigid, over-complex, monolithic design.",
              }}
              style={{ color: "text-white" }}
            />
            <div className="row">
              <div className="col-12 col-md-6">
                <img
                  style={{
                    height: "64px",
                    width: "64px",
                    margin: "2rem auto",
                    display: "block",
                  }}
                  src="https://taact-brand-assets.netlify.app/assets/icons/jetti/shipping-reverse.svg"
                  data-sal="fade"
                  data-sal-easing="ease"
                  data-sal-duration="900"
                />
                <h2
                  style={{ fontSize: "1rem" }}
                  className="text-white text-center"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="600"
                >
                  Shipping
                </h2>
                <p
                  className="w-75 mx-auto"
                  style={{
                    textAlign: "center",
                    fontSize: "0.9rem",
                    fontWeight: "300",
                    lineHeight: "2",
                    color: "white",
                  }}
                  data-sal="slide-down"
                  data-sal-delay="600"
                  data-sal-easing="ease"
                  data-sal-duration="600"
                >
                  "With Onport, you can split single POs into multiple shipments
                  seamlessly. APIs are available for proving a breakdown of the
                  shipping lead times and costs for items within a customer’s
                  basket.
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  style={{
                    height: "64px",
                    width: "64px",
                    margin: "2rem auto",
                    display: "block",
                  }}
                  src="https://taact-brand-assets.netlify.app/assets/icons/jetti/inventory-reverse.svg"
                  data-sal="fade"
                  data-sal-easing="ease"
                  data-sal-duration="900"
                />
                <h2
                  style={{ fontSize: "1rem" }}
                  className="text-white text-center"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="600"
                >
                  Inventory Management
                </h2>
                <p
                  className="w-75 mx-auto"
                  style={{
                    textAlign: "center",
                    fontSize: "0.9rem",
                    fontWeight: "300",
                    lineHeight: "2",
                    color: "white",
                  }}
                  data-sal="slide-down"
                  data-sal-delay="600"
                  data-sal-easing="ease"
                  data-sal-duration="600"
                >
                  Alongside the handling of product data, Onport provides timely
                  sync of inventory and pricing between your dropship partners.
                  For architectures where a full two-way PIM integration is not
                  needed, one-way sync from the marketplace PIM into Onport can
                  be implemented as an alternative.
                </p>
              </div>
              <div className="col-12 col-md-6 mt-5 pt-5">
                <img
                  style={{
                    height: "64px",
                    width: "64px",
                    margin: "2rem auto",
                    display: "block",
                  }}
                  src="https://taact-brand-assets.netlify.app/assets/icons/jetti/returns-reverse.svg"
                  data-sal="fade"
                  data-sal-easing="ease"
                  data-sal-duration="900"
                />
                <h2
                  style={{ fontSize: "1rem" }}
                  className="text-white text-center"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="600"
                >
                  Payments and Returns
                </h2>
                <p
                  className="w-75 mx-auto"
                  style={{
                    textAlign: "center",
                    fontSize: "0.9rem",
                    fontWeight: "300",
                    lineHeight: "2",
                    color: "white",
                  }}
                  data-sal="slide-down"
                  data-sal-delay="600"
                  data-sal-easing="ease"
                  data-sal-duration="600"
                >
                  Onport provides a platform and API for managing the commission
                  routing for sellers and a customer-facing portal for the
                  generation and management of returns.
                </p>
              </div>
              <div className="col-12 col-md-6 mt-5 pt-5">
                <img
                  style={{
                    height: "64px",
                    width: "64px",
                    margin: "2rem auto",
                    display: "block",
                  }}
                  src="https://taact-brand-assets.netlify.app/assets/icons/jetti/routing-reverse.svg"
                  data-sal="fade"
                  data-sal-easing="ease"
                  data-sal-duration="900"
                />
                <h2
                  style={{ fontSize: "1rem" }}
                  className="text-white text-center"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="600"
                >
                  Order Routing
                </h2>
                <p
                  className="w-75 mx-auto"
                  style={{
                    textAlign: "center",
                    fontSize: "0.9rem",
                    fontWeight: "300",
                    lineHeight: "2",
                    color: "white",
                  }}
                  data-sal="slide-down"
                  data-sal-delay="600"
                  data-sal-easing="ease"
                  data-sal-duration="600"
                >
                  Custom routing rules can be supported through our API.
                  Alternatively, routing preferences based on product,
                  inventory, or seller information can be configured within the
                  Onport dashboard.
                </p>
              </div>
            </div>
          </Container>
        </Container>
        <Container style={{ classes: "container-fluid dark-blue" }}>
          <Container
            style={{ classes: "container py-5 mb-5 position-relative" }}
          >
            <SubHeader
              content={{
                title: "A Headless Approach",
                text:
                  "At Onport, we believe a headless approach to product development addresses the modern reality of building systems at scale - no single component is an island. Business systems should assume they are part of <b>dynamic, composable architecture</b> and provide the flexibility to adjust to the demands of modern commerce and operations. Each element can be customized <b>individually</b> as needed, avoiding costly and lengthy implementations. <br/><br/>Our API-forward approach enables us to handle <b>millions of API calls</b> every day and operate in a decoupled manner which allows for seamless integration and communication as needed. Onport is designed with the developer in mind, giving them a number of pre-existing infrastructure components to save time and launch their integrations faster.<br/></br>In line with our commitment to a thorough <b>API-first approach</b>, Onport has developed a <b>pipeline</b> that can clearly demonstrate the effectiveness and versatile nature of our API.",
              }}
              style={{ color: "text-white mt-5" }}
            />
			<Button style={{ shape: "btn-contact position-static m-0", text: "Learn more", href: "/api-pipeline"}}/>
            <img
              style={{
                position: "absolute",
                right: "-45%",
                top: "20%",
                height: "500px",
              }}
              className="desktop-only"
              src={image4}
              data-sal="fade"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
          </Container>
        </Container>
        <Container
          style={{ classes: "container-fluid very-dark-blue mx-0 px-0" }}
        >
          <Container style={{ classes: "container pb-5" }}>
            <SubHeader
              content={{
                title: "Developer Portal",
                text:
                  "Our developer portal is designed to facilitate the flexibility required to rapidly and effectively respond to changing marketing conditions and organizational needs. Our pre-composed infrastructure components offer optional functionality but do not seek to impose it, unlike many out-of-the-box solutions which further restrict and narrow scaling opportunities as business requirements grow.",
              }}
              style={{ color: "text-white mt-5" }}
            />
            <div className="d-flex align-items-center ml-5 mb-5 ">
              <p
                className="w-50"
                style={{
                  textAlign: "left",
                  fontSize: "0.9rem",
                  fontWeight: "300",
                  lineHeight: "2",
                  color: "white",
                }}
                data-sal="slide-right"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="600"
              >
                Our <b>real-time logs</b> give a detailed feed of shipping,
                transfers, transactions, returns, inventory feeds, accounting,
                and tracking. Providing valuable oversight into the flow of data
                between Onport and external systems, browsing status requests,
                inspecting API calls, and accelerating development.
              </p>
              <img
                style={{
                  height: "64px",
                  width: "64px",
                  margin: "2rem auto",
                  display: "block",
                }}
                src="https://taact-brand-assets.netlify.app/assets/icons/jetti/reasons-reverse.svg"
                data-sal="slide-right"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="900"
              />
            </div>
            <div className="d-flex align-items-center ml-5 mb-5 ">
              <p
                className="w-50"
                style={{
                  textAlign: "left",
                  fontSize: "0.9rem",
                  fontWeight: "300",
                  lineHeight: "2",
                  color: "white",
                }}
                data-sal="slide-right"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="600"
              >
                Our <b>channel management</b> grants access to API keys and the
                ability to view the status of orders, inventories, and reports
                in real-time. Using Semver, we ensure that users have the
                ability to manage our releases in a predictable manner, ensuring
                their integrations are always compatible with our updates,
                reducing launch time and possible friction between components.
              </p>
              <img
                style={{
                  height: "64px",
                  width: "64px",
                  margin: "2rem auto",
                  display: "block",
                }}
                src="https://taact-brand-assets.netlify.app/assets/icons/jetti/channel-reverse.svg"
                data-sal="slide-right"
                data-sal-delay="600"
                data-sal-easing="ease"
                data-sal-duration="900"
              />
            </div>
            <div className="d-flex align-items-center ml-5 mb-5 ">
              <p
                className="w-50"
                style={{
                  textAlign: "left",
                  fontSize: "0.9rem",
                  fontWeight: "300",
                  lineHeight: "2",
                  color: "white",
                }}
                data-sal="slide-right"
                data-sal-delay="600"
                data-sal-easing="ease"
                data-sal-duration="600"
              >
                Our <b>dedicated support portal</b> is operated by real
                developers who can walk you through any aspect of our enterprise
                solution.{" "}
              </p>
              <img
                style={{
                  height: "64px",
                  width: "64px",
                  margin: "2rem auto",
                  display: "block",
                }}
                src="https://taact-brand-assets.netlify.app/assets/icons/jetti/fast-reverse.svg"
                data-sal="slide-right"
                data-sal-delay="900"
                data-sal-easing="ease"
                data-sal-duration="900"
              />
            </div>
          </Container>
        </Container>
        <Container style={{ classes: "container mb-0 pb-5 " }}>
          <Container style={{ classes: "py-5 position-relative" }}>
            <SubHeader
              content={{
                title: "Developer Tools",
                text:
                  "We believe in giving developers all they need to run new integrations, our tools are designed to facilitate innovation at all times without compromise or restricting functionality.",
              }}
              style={{ color: "text-white mt-0" }}
            />
            <p
              className="ml-5 mb-5 w-50"
              style={{
                textAlign: "left",
                fontSize: "0.9rem",
                fontWeight: "300",
                lineHeight: "2",
                color: "white",
              }}
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="600"
            >
              Our <b>command-line interface module</b> allows Onport to be run
              from the command line without having to use the web interface.
              This ensures a more granular system management approach, such as
              for custom reporting, API tokens, and allows for rapid results.
            </p>
            <p
              className="ml-5 mb-5 w-50"
              style={{
                textAlign: "left",
                fontSize: "0.9rem",
                fontWeight: "300",
                lineHeight: "2",
                color: "white",
              }}
              data-sal="slide-right"
              data-sal-delay="400"
              data-sal-easing="ease"
              data-sal-duration="600"
            >
              Our <b>software development kits</b> are designed for those who
              wish to rapidly develop composable marketplace architecture,
              allowing for faster launch time and the ability to be
              uncompromising in bringing their brand vision to life. Each
              individual element of MACH is designed to help developers in
              creating flexible and customized solutions with minimal disruption
              - it is this core value that Onport seeks to embody at every stage
              of its enterprise stack development offering.
            </p>
            <img
              style={{
                position: "absolute",
                right: "-45%",
                top: " 20%",
                height: "500px",
              }}
              className="desktop-only"
              src={image5}
              data-sal="fade"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
          </Container>
        </Container>
      </Container>
      <Container style={{ classes: "container-fluid bg-blue py-5" }}>
        <Container
          style={{
            classes:
              "container d-flex flex-column justify-content-around align-items-center py-5",
          }}
        >
          <SubHeader
            content={{ title: "Get started", text: "" }}
            style={{ color: "text-light text-center mx-auto my-0 py-0" }}
          />
          <p
            style={{
              fontSize: "1rem",
              fontWeight: "300",
              color: "white",
              marginBottom: "3rem",
            }}
          >
            {
              "Empower your business & operations with our headless enterprise technology and extensive feature set. "
            }
          </p>
          <a href="/contacts/book-demo" className="btn-home">
            Contact us
          </a>
        </Container>
        <Container
          style={{
            classes:
              "container pb-5",
          }}
        >
        <SubHeader
            content={{ title: "", text: "Or download our headless documentation" }}
            style={{ color: "text-light text-center mx-auto my-0 py-0" }}
            />
            <HubspotContactFormPartner id="c4e8c8b0-d381-4103-9f32-3fa08eed21b6" />
            </Container>
      </Container>
    </Layout>
  )
}
